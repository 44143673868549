const messages = {
    en: {
        contact_information: 'Contact Information',
        contact_information_description: 'The information will be used to contact you about your service',

        service_address: 'Service Address',
        service_address_description: 'Where would you like us to clean?',

        service_category: 'Choose Your Category',
        service_category_description: 'What kind of service are you looking for?',

        services_title: 'Choose Your Services',
        services_description: 'Tell us about your home',

        extras_title: 'Select Extras',
        extras_description: 'Add upgrades to your service',

        when_to_come : 'When would you like us to come?',
        when_to_come_description : 'The day and time that suits you best',
        how_often: 'How Often?',
        how_often_description: 'It\'s all about matching you with the perfect cleaner for your home. Scheduling is flexible. Cancel or reschedule anytime.',
        how_often_discount: ' Discount applies after initial service',
        notes: 'Would you like to add any Notes?',
        notes_description: 'Share with us more details about your home',
        discount: 'Do you have a Promo Code?',

        cc_details: 'Credit Card Details',
        cc_description: 'Enter your card information below. You will be charged after service has been rendered.',

        something_wrong: 'Something went wrong',
        complete_request: 'Please complete the form to submit.',
        notifications_consent: 'I’m giving my consent to receive SMS and email notifications',
        submit_button: 'Complete Booking',
        stripe_keys: 'Please connect your Stripe account',

        cleaning_plans: 'Cleaning Plan',
        per_cleaning: 'Per Cleaning',
        discount_code: 'Discount Code',
        selected_extras: 'Selected Extras',
        frequency_discount: 'Frequency Discount',
        gift_card: 'Gift Card',
        frequency_fee: 'Frequency Fee',
        referral_balance: 'Referrals Balance',
        apply_balance: 'Apply',
        questions: 'Questions?',

        // Input placeholders
        placeholder_first_name: 'First Name',
        placeholder_last_name: 'Last Name',
        placeholder_email: 'Email Address',
        placeholder_phone: 'Phone Number',
        placeholder_street: 'Street',
        placeholder_apt: 'Apt #',
        placeholder_city: 'City',
        placeholder_state: 'State',
        placeholder_zip: 'Zip Code',
        placeholder_day: 'Pick a day',
        placeholder_arrival: 'Pick an arrival window',
        placeholder_time:  'Pick a time',
        placeholder_notes: 'Property access information, your dog’s name, etc',
        placeholder_promo: 'Promo Code',

        // Input placeholders (New Booking Form)
        new_placeholder_first_name: 'John',
        new_placeholder_last_name:'Doe',
        new_placeholder_email: 'johndoe@mail.com',
        new_placeholder_phone:'1234567890',
        new_placeholder_street: '123 Main Street',
        new_placeholder_apt: 'Apt 101',
        new_placeholder_city: 'Anytown',
        new_placeholder_state: 'State',
        new_placeholder_zip: '67890',
        new_placeholder_day: 'Pick a day',
        new_placeholder_arrival: 'Pick an arrival window',
        new_placeholder_time: 'Pick a time',
        new_placeholder_notes: 'Property access information, your dog’s name, etc',
        new_placeholder_promo: 'Promo Code',
    },

    es: {
        contact_information: 'Información de contacto',
        contact_information_description: 'La información se utilizará para comunicarnos contigo sobre tu servicio',

        service_address: 'Dirección del servicio',
        service_address_description: '¿Dónde te gustaría que limpiemos?',

        service_category: 'Elige tu categoría',
        service_category_description: 'Selecciona una categoria de servicio',

        services_title: 'Elige tu servicio',
        services_description: 'Cuéntanos acerca de tu hogar',

        extras_title: 'Selecciona Extras',
        extras_description: 'Agrega mejoras a tu servicio',

        when_to_come: '¿Cuándo te gustaría que viniéramos?',
        when_to_come_description : 'El día y hora que mejor se adapte a ti',
        how_often: '¿Con qué frecuencia?',
        how_often_description: 'Se trata de emparejarte con la persona adecuada para limpiar tu hogar. La reserva es flexible. Cancela o reprograma en cualquier momento',
        how_often_discount: 'Descuento por frecuencia aplicable solo después del primer servicio',
        notes: '¿Te gustaría agregar alguna nota?',
        notes_description: 'Comparte con nosotros más detalles sobre tu hogar.',
        discount: '¿Tienes algún código de promoción?',

        cc_details: 'Detalles de la tarjeta de crédito',
        cc_description: 'Ingresa la información de tu tarjeta. Se te cobrará después de que se haya realizado el servicio',

        something_wrong: 'Algo salió mal',
        complete_request: 'Por favor, completa el formulario para enviar',
        notifications_consent: 'Doy mi consentimiento para recibir notificaciones por SMS y correo electrónico',
        submit_button: 'Completar reserva',
        stripe_keys: 'Por favor conecta tu cuenta de Stripe',

        cleaning_plans: 'Plan de limpieza',
        per_cleaning: 'Por limpieza',
        discount_code: 'Código de descuento',
        selected_extras: 'Extras seleccionados',
        frequency_discount: 'Descuento por frecuencia',
        gift_card: 'Tarjeta de regalo',
        frequency_fee: 'Tarifa de frecuencia',
        referral_balance: 'Saldo de referidos',
        apply_balance: 'Aplicar',
        questions: '¿Preguntas?',

        placeholder_first_name: 'Nombre',
        placeholder_last_name: 'Apellido',
        placeholder_email: 'Correo electrónico',
        placeholder_phone: 'Número de teléfono',
        placeholder_street: 'Dirección, sector calle, etc',
        placeholder_apt: 'Apt #',
        placeholder_city: 'Ciudad',
        placeholder_state: 'Estado',
        placeholder_zip: 'Código postal',
        placeholder_promo: 'Código promocional (o déjelo en blanco)',
        placeholder_day: 'Seleccione un día',
        placeholder_arrival: 'Selecciona un rango de llegada',
        placeholder_time:  'Selecciona una hora',
        placeholder_notes: 'Información de acceso a la propiedad, nombre de tu perro, etc'

    }

};

export default messages;
